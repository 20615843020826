import "./App.css";
import EventList from "./components/EventList";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import { useLocalStorage } from "./hooks/useStorage";

import { getDefaultPlayerInfo } from "./data/models/Player";
import { getExampleState } from "./data/models/State";
import { AddEventContainer } from "./components/AddEvent";
import { PlayerNotesContainer } from "./components/PlayerNotes";

import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  const [state, setState] = useLocalStorage("state", getExampleState());
  const [addEventCollapsed, setAddEventCollapsed] = useLocalStorage("addEventCollapsed", false);
  const currentGame = state.games[state.selectedGame];

  const setProperty = (property, value) => {
    const newState = { ...state };
    newState.games[state.selectedGame][property] = value;
    setState(newState);
  };

  //===== Old data fixes =====
  if (currentGame.players.length > 0 && typeof currentGame.players[0] === "string") {
    const fixedGame = fixGameData(currentGame);
    setProperty("players", fixedGame.players);
  }
  if (currentGame.datePlayed === undefined || currentGame.datePlayed === null) {
    //Set the date played to UNIX epoch as Date object
    setProperty("datePlayed", new Date(0));
  }
  if (typeof currentGame.datePlayed === "string") {
    setProperty("datePlayed", new Date(currentGame.datePlayed));
  }
  //==========================

  return (
    <>
      <div id="main-container">
        <div id="left-sidebar">
          <LeftSidebar
            playerList={currentGame.players}
            setPlayerList={(value) => setProperty("players", value)}
            bluffs={currentGame.bluffs}
            setBluffs={(value) => setProperty("bluffs", value)}
            state={state}
            setState={setState}
          />
        </div>
        <div id="main-content">
          <EventList
            events={currentGame.events}
            setEvents={(value) => setProperty("events", value)}
            players={currentGame.players}
            script={currentGame.script}
          />
          <div id="sub-content">
            <AddEventContainer
              events={currentGame.events}
              setEvents={(value) => setProperty("events", value)}
              game={currentGame}
              collapsed={addEventCollapsed}
              setCollapsed={setAddEventCollapsed}
            />
            <PlayerNotesContainer
              players={currentGame.players}
              setPlayers={(value) => setProperty("players", value)}
              events={currentGame.events}
            />
          </div>
          <div style={{ height: "900px" }}></div>
        </div>
        <div id="right-sidebar">
          <RightSidebar
            script={currentGame.script}
            setScript={(value) => setProperty("script", value)}
            state={state}
          />
        </div>
      </div>
    </>
  );
}

function fixGameData(game) {
  game.players = game.players.map((player) => {
    return { ...getDefaultPlayerInfo(), name: player };
  });

  return game;
}

import { getDefaultGame } from "./Game";

export function getExampleState() {
  return {
    games: [
      getDefaultGame(),
      {
        players: ["viddie", "Unnamed", "ad", "Corrosive", "qwerty", "Splod", "karl", "Spooph"],
        bluffs: [null, null, null],
        script: {
          name: "Sects & Violets",
          characters: {
            Townsfolk: [
              "Artist",
              "Clockmaker",
              "Dreamer",
              "Flowergirl",
              "Juggler",
              "Mathematician",
              "Oracle",
              "Philosopher",
              "Sage",
              "Savant",
              "Seamstress",
              "Snake Charmer",
              "Town Crier",
            ],
            Outsider: ["Barber", "Klutz", "Mutant", "Sweetheart"],
            Minion: ["Cerenovus", "Evil Twin", "Pit-Hag", "Witch"],
            Demon: ["Fang Gu", "No Dashii", "Vigormortis", "Vortox"],
          },
        },
        events: [
          {
            phase: "Night",
            type: "Received Role",
            activePlayers: [],
            activeCharacters: [
              {
                character: "No Dashii",
                characterType: "Demon",
              },
            ],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Night",
            type: "Evil Info",
            activePlayers: ["karl", "qwerty"],
            activeCharacters: [
              {
                character: "Cerenovus",
                characterType: "Minion",
              },
              {
                character: "Witch",
                characterType: "Minion",
              },
            ],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [
              {
                character: "Artist",
                characterType: "Townsfolk",
              },
              {
                character: "Savant",
                characterType: "Townsfolk",
              },
              {
                character: "Sage",
                characterType: "Townsfolk",
              },
            ],
          },
          {
            phase: "Day",
            type: "Exchanged Roles",
            activePlayers: ["Corrosive"],
            activeCharacters: [
              {
                character: "Mutant",
                characterType: "Outsider",
              },
            ],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Exchanged Roles",
            activePlayers: ["karl"],
            activeCharacters: [
              {
                character: "Artist",
                characterType: "Townsfolk",
              },
              {
                character: "Savant",
                characterType: "Townsfolk",
              },
              {
                character: "Mathematician",
                characterType: "Townsfolk",
              },
            ],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Exchanged Roles",
            activePlayers: ["Unnamed"],
            activeCharacters: [
              {
                character: "Sage",
                characterType: "Townsfolk",
              },
              {
                character: "Philosopher",
                characterType: "Townsfolk",
              },
              {
                character: "Clockmaker",
                characterType: "Townsfolk",
              },
            ],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Information",
            activePlayers: [],
            activeCharacters: [
              {
                character: "Savant",
                characterType: "Townsfolk",
              },
            ],
            notes: "- Outsider count has been modified\n- An outsider neighbours an evil player",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Nominated",
            activePlayers: ["viddie", "ad"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Nominated",
            activePlayers: ["Spooph", "Corrosive"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Nominated",
            activePlayers: ["Corrosive", "Spooph"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Executed",
            activePlayers: ["Spooph"],
            activeCharacters: [
              {
                character: "Klutz",
                characterType: "Outsider",
              },
            ],
            notes: "",
            passivePlayers: ["Corrosive"],
            passiveCharacters: [],
          },
          {
            phase: "Night",
            type: "Ability",
            activePlayers: ["Unnamed"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Night",
            type: "Died During Night",
            activePlayers: ["Unnamed"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Claim",
            activePlayers: ["ad"],
            activeCharacters: [
              {
                character: "Juggler",
                characterType: "Townsfolk",
              },
            ],
            notes: "1",
            passivePlayers: ["ad", "Corrosive", "qwerty", "qwerty"],
            passiveCharacters: [
              {
                character: "Juggler",
                characterType: "Townsfolk",
              },
              {
                character: "Mutant",
                characterType: "Outsider",
              },
              {
                character: "Sage",
                characterType: "Townsfolk",
              },
              {
                character: "Mathematician",
                characterType: "Townsfolk",
              },
            ],
          },
          {
            phase: "Day",
            type: "Information",
            activePlayers: [],
            activeCharacters: [],
            notes:
              "- at least 2 players got wrong info last night\n- a player had 4 reminder tokens on them last night",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Nominated",
            activePlayers: ["Splod", "Unnamed"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Executed",
            activePlayers: ["Unnamed"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Night",
            type: "Ability",
            activePlayers: ["qwerty"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Night",
            type: "Died During Night",
            activePlayers: ["qwerty"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Nominated",
            activePlayers: [],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Executed",
            activePlayers: ["ad"],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Received Role",
            activePlayers: [],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
          {
            phase: "Day",
            type: "Exchanged Roles",
            activePlayers: [],
            activeCharacters: [],
            notes: "",
            passivePlayers: [],
            passiveCharacters: [],
          },
        ],
        datePlayed: new Date("2023-11-11T00:00:00.000Z"),
      },
    ],
    selectedGame: 0,
  };
}

export const EVENTS = {
  Game: [
    "None",
    "Ability",
    "Information",
    "Received Role",
    "Evil Info",
    "Changed Alignment",
    "Changed Role",
    "Madness",
  ],
  Player: ["Exchanged Roles", "Claim", "Suspicion"],
  Death: ["Died During Night", "Died During Day", "Resurrected"],
  Nominations: ["Nominated", "Executed", "Executed But Lived", "Executed No One", "Executed Dead Player"],
  Other: ["Comment", "Gossip", "Juggle", "Talked"],
  EndGame: ["Round Robin", "Winner: Evil", "Winner: Good"],
};
export const ENDING_EVENTS = ["None", "Winner: Evil", "Winner: Good"];

export const EVENTS_STYLE = {
  None: { primary: "white", secondary: "black", ambient: "transparent" },
  Death: { primary: "#ffcfc9", secondary: "#b10202", ambient: "red" },
  WinnerEvil: { primary: "#ffcfc9", secondary: "#b10202", ambient: "red" },
  DeathPrevention: { primary: "#ffe5a0", secondary: "#473821", ambient: "transparent" },
  Resurrection: { primary: "#d4edbc", secondary: "#11734b", ambient: "transparent" },
  ExchangedRoles: { primary: "#bfe1f6", secondary: "#0a53a8", ambient: "transparent" },
  WinnerGood: { primary: "#bfe1f6", secondary: "#0a53a8", ambient: "transparent" },
  GameInfo: { primary: "#e6cff2", secondary: "#5a3286", ambient: "transparent" },
  RoleAbility: { primary: "#c6dbe1", secondary: "#215a6c", ambient: "transparent" },
  Public: { primary: "#ffc8aa", secondary: "#753800", ambient: "transparent" },
};
export const EVENTS_MAPPING = {
  None: ["None"],
  Death: ["Died During Day", "Died During Night", "Executed"],
  WinnerEvil: ["Winner: Evil"],
  DeathPrevention: ["Executed No One", "Executed Dead Player", "Executed But Lived", "Suspicion"],
  Resurrection: ["Resurrected"],
  ExchangedRoles: ["Exchanged Roles", "Claim"],
  WinnerGood: ["Winner: Good"],
  GameInfo: ["Received Role", "Evil Info", "Changed Alignment", "Changed Role", "Madness"],
  RoleAbility: ["Ability", "Information"],
  Public: ["Nominated", "Comment", "Gossip", "Juggle", "Talked"],
};

export const EVENTS_DEFAULT_ELEMENTS = {
  "Received Role": [0, 1, 0, 0],
  "Evil Info": [1, 1, 0, 3],
  Information: [0, 0, 0, 0],
  "Changed Alignment": [0, 0, 0, 0],
  "Changed Role": [0, 1, 0, 0],
  "Exchanged Roles": [1, 1, 0, 0],
  Claim: [1, 0, 0, 0],
  Suspicion: [1, 0, 0, 0],
  "Died During Day": [1, 0, 0, 0],
  "Died During Night": [1, 0, 0, 0],
  Resurrected: [1, 0, 0, 0],
  Nominated: [2, 0, 0, 0],
  Executed: [1, 0, 0, 0],
  "Executed But Lived": [1, 0, 0, 0],
  "Executed Dead Player": [1, 0, 0, 0],
  Comment: [0, 0, 0, 0],
  Gossip: [1, 0, 0, 0],
  Juggle: [1, 0, 0, 0],
  Talked: [2, 0, 0, 0],

  default: [0, 0, 0, 0],
};
export function getEventDefaultElementCounts(event) {
  if (event in EVENTS_DEFAULT_ELEMENTS) {
    return EVENTS_DEFAULT_ELEMENTS[event];
  }
  return EVENTS_DEFAULT_ELEMENTS.default;
}

export function getEventStyle(event) {
  for (const key in EVENTS_MAPPING) {
    if (EVENTS_MAPPING[key].indexOf(event) !== -1) {
      return EVENTS_STYLE[key];
    }
  }
  return EVENTS_STYLE["None"];
}

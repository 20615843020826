import "./RightSidebar.css";
import { ScriptDisplay } from "./Character";

export default function RightSidebar({ script, setScript, state }) {
  return (
    <div id="right-sidebar-container" style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ScriptDisplay script={script} setScript={setScript} state={state} />
      </div>
    </div>
  );
}

import { useState } from "react";
import { EVENTS, getEventStyle } from "../data/models/Events";
import "./AddEvent.css";
import { PhaseButton } from "./BotcComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

export function AddEventContainer({ events, setEvents, game, collapsed, setCollapsed }) {
  const [phase, setPhase] = useState("Night");

  //EVENTS is an object with keys for the event groups, and values that are arrays of events
  //Add one div for each event group, and inside each div add one button for each event in the group
  //The group has display:flex
  //When an event button is clicked, fire the addEvent callback with { phase:eventList[eventList.length-1].phase, type: event, activePlayers:[], activeCharacters:[], notes:"", passivePlayers:[], passiveCharacters:[] }
  const createEvent = (name) => {
    let notes = "";
    if (name === "Round Robin") {
      //Find longest name
      let longestName = "";
      for (let i = 0; i < game.players.length; i++) {
        if (game.players[i].name.length > longestName.length) {
          longestName = game.players[i].name;
        }
      }

      //Create notes string
      //Iterate through players, adding: " ".repeat(longestName.length - playerName.length) + "PlayerName:"
      for (let i = 0; i < game.players.length; i++) {
        const name = game.players[i].name;
        notes += " ".repeat(longestName.length - name.length) + name + ": ";
        if (i < game.players.length - 1) {
          notes += "\n";
        }
      }
    }

    const newEvent = {
      phase: phase,
      type: name,
      activePlayers: [],
      activeCharacters: [],
      notes: notes,
      passivePlayers: [],
      passiveCharacters: [],
    };
    setEvents([...events, newEvent]);
  };

  if (collapsed) {
    return (
      <div id="add-event-container" className="collapsed" onClick={() => setCollapsed(false)}>
        <h2>Add Events</h2>
      </div>
    );
  }

  return (
    <div id="add-event-container">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button onClick={() => setCollapsed(true)} variant="outline-light">
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <h2 style={{ flexGrow: "1" }}>Add Events</h2>
        <PhaseButton phase={phase} setPhase={setPhase} number={null} style={{ minWidth: "100px" }} />
      </div>
      {Object.keys(EVENTS).map((group, index) => {
        return (
          <div key={index} className="add-event-group-outer" style={{ flexWrap: "nowrap" }}>
            <h3>
              <u>{group}</u>
            </h3>
            <div className="add-event-group">
              {EVENTS[group].map((event, index) => {
                if (event === "None") return null;
                const style = getEventStyle(event);
                return (
                  <button
                    key={index}
                    className="add-event-button"
                    style={{
                      backgroundColor: style.primary,
                      color: style.secondary,
                      borderColor: style.secondary,
                    }}
                    onClick={() => createEvent(event)}
                  >
                    {event}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

import { Badge, Button } from "react-bootstrap";
import { EVENTS, getEventStyle } from "../data/models/Events";
import "./BotcComponents.css";
import { faQuestion, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PhaseButton({ phase, setPhase, number, style }) {
  const onClick = () => {
    const newPhase = phase === "Night" ? "Day" : "Night";
    setPhase(newPhase);
  };

  let classes = "phase-button " + phase.toLowerCase();

  return (
    <button className={classes} onClick={onClick} style={style}>
      {phase + " " + (number === null ? "" : number)}
    </button>
  );
}

export function EventTypeComboBox({ type, setType }) {
  //EVENTS is an object with keys "Game", "Nominations", "Player" and "Other", where the values are arrays of strings
  const eventTypes = Object.keys(EVENTS);

  const onChange = (event) => {
    const newEvent = event.target.value;
    setType(newEvent);
  };

  const eventStyle = getEventStyle(type);
  const style = {
    backgroundColor: eventStyle.primary,
    color: eventStyle.secondary,
    border: "1px solid " + eventStyle.secondary,
  };

  return (
    <select className="event-select" value={type} onChange={onChange} style={style}>
      {eventTypes.map((eventType) => (
        <optgroup key={eventType} label={eventType}>
          {EVENTS[eventType].map((event) => (
            <option key={event} value={event}>
              {event}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );
}

export function AlignmentButton({
  alignment,
  setAlignment,
  editable = true,
  stopPropagation = false,
  style,
}) {
  //alignment is either "Good", "Evil" or null

  const onClick = (e) => {
    if (stopPropagation) e.stopPropagation();
    if (!editable) return;

    let newAlignment = null;
    if (alignment === null) {
      newAlignment = "Good";
    } else if (alignment === "Good") {
      newAlignment = "Evil";
    } else if (alignment === "Evil") {
      newAlignment = null;
    }
    setAlignment(newAlignment);
  };

  let classes = "alignment-button " + (alignment ?? "Neutral").toLowerCase();
  let icon = alignment === null ? faQuestion : alignment === "Good" ? faThumbsUp : faThumbsDown;
  let text = alignment === null ? "Unknown" : alignment;
  let variant = alignment === null ? "secondary" : alignment === "Good" ? "primary" : "danger";

  if (!editable) {
    return (
      <Badge bg={variant} style={style}>
        {text} <FontAwesomeIcon icon={icon} />
      </Badge>
    );
  }

  return (
    <Button className={classes} onClick={onClick} style={style} variant={variant} size="sm">
      {text} <FontAwesomeIcon icon={icon} />
    </Button>
  );
}

export function getAlignmentFromCharacter(character) {
  if (character === null) return null;
  const type = character.characterType;
  //Townsfolk and Outsiders are Good, Minions and Demon are Evil
  return type === "Townsfolk" || type === "Outsider" ? "Good" : "Evil";
}

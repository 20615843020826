import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "react-bootstrap";

export function TransparentHorizontalRule({ style }) {
  return <hr style={{ ...style, color: "transparent" }} />;
}

export function EditButton({ onClick, text }) {
  return (
    <Button className="edit-button" variant="outline-secondary" onClick={onClick} size="sm">
      {text ? text + " " : null}
      <FontAwesomeIcon icon={faEdit} />
    </Button>
  );
}

export function DoubleConfirmButton({ onClick, onConfirm, text, confirmText, children }) {
  const [confirming, setConfirming] = useState(false);

  const confirm = (e) => {
    if (confirming) {
      onConfirm(e);
      setConfirming(false);
    } else {
      onClick(e);
      setConfirming(true);

      setTimeout(() => {
        setConfirming(false);
      }, 3000);
    }
  };

  return (
    <Button variant="outline-danger" onClick={confirm} size="sm">
      {confirming ? confirmText : text}
      {children}
    </Button>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEventStyle, getEventDefaultElementCounts, EVENTS } from "../data/models/Events";
import { EventTypeComboBox, PhaseButton } from "./BotcComponents";
import { CharacterDisplay, DeleteButton } from "./Character";
import "./EventList.css";
import { PlayerDisplay } from "./Player";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

export default function EventList({ events, setEvents, players, script }) {
  const phaseNumberList = [];
  let lastPhase = null;
  let phaseNumber = 1;
  for (let i = 0; i < events.length; i++) {
    //Loop through events, look at the phase of each event and compare it to the last phase
    //If its the same phase, dont increment the phase number
    //If its a different phase, increment the phase number
    //Add the phase number to the phaseNumberList

    const event = events[i];
    const phase = event.phase;
    if (lastPhase === null || phase !== lastPhase) {
      if (lastPhase === "Day") {
        phaseNumber++;
      }
      lastPhase = phase;
    }
    phaseNumberList.push(phaseNumber);
  }

  const setEvent = (event, index) => {
    const newEvents = [...events];
    newEvents[index] = event;
    setEvents(newEvents);
  };
  const deleteEvent = (index) => {
    const newEvents = [...events];
    newEvents.splice(index, 1);
    setEvents(newEvents);
  };
  const moveEvent = (index, direction) => {
    const newEvents = [...events];
    const temp = newEvents[index];
    newEvents[index] = newEvents[index + direction];
    newEvents[index + direction] = temp;
    setEvents(newEvents);
  };
  const addEvent = (index, type, currentEvent) => {
    const newEvents = [...events];
    newEvents.splice(index + 1, 0, {
      type: type,
      phase: currentEvent.phase,
      activePlayers: [],
      activeCharacters: [],
      passivePlayers: [],
      passiveCharacters: [],
      notes: "",
    });
    //Also update the current event
    newEvents[index] = currentEvent;
    setEvents(newEvents);
  };

  let lastPhaseRender = "";

  const eventElement = (event, index) => {
    const canMoveUp = index > 0;
    const canMoveDown = index < events.length - 1;

    const winner = event.type === "Winner: Good" ? true : event.type === "Winner: Evil" ? false : null;
    const winnerElem = <PhaseSeparatorWinner goodWon={winner} />;

    return (
      <>
        <div className="event-container">
          <div className="event-move">
            <Button disabled={!canMoveUp} onClick={() => moveEvent(index, -1)} variant="secondary" size="sm">
              <FontAwesomeIcon icon={faArrowUp} size="xs" />
            </Button>
            <Button disabled={!canMoveDown} onClick={() => moveEvent(index, 1)} variant="secondary" size="sm">
              <FontAwesomeIcon icon={faArrowDown} size="xs" />
            </Button>
          </div>
          <Event
            key={index}
            event={event}
            setEvent={(event) => setEvent(event, index)}
            phaseNumber={phaseNumberList[index]}
            deleteEvent={() => deleteEvent(index)}
            players={players}
            script={script}
            addEvent={(type, event) => addEvent(index, type, event)}
          />
        </div>
        {winner !== null ? winnerElem : null}
      </>
    );
  };

  return (
    <div id="event-list-container">
      {events.map((event, index) => {
        if (event.phase !== lastPhaseRender) {
          lastPhaseRender = event.phase;
          return (
            <>
              <PhaseSeparator key={index + 1000} phase={event.phase} phaseNumber={phaseNumberList[index]} />
              {eventElement(event, index)}
            </>
          );
        }
        return eventElement(event, index);
      })}
    </div>
  );
}

function Event({ event, setEvent, phaseNumber, deleteEvent, players, script, addEvent }) {
  const setPlayerList = (listName, list) => {
    const newEvent = { ...event };
    newEvent[listName] = list;
    setEvent(newEvent);
  };
  const setCharacterList = (listName, list) => {
    const newEvent = { ...event };
    newEvent[listName] = list;
    setEvent(newEvent);
  };
  const setNotes = (notes) => {
    const newEvent = { ...event };

    //Split to lines and look at each line
    const lines = notes.split("\n");
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();

      //If the line ends with a "." then it is a command
      if (!line.endsWith(".") && !line.endsWith(",") && !line.endsWith("+")) continue;

      //, means active, . means passive
      let commandChar = line[line.length - 1];
      let side = "passive";
      if (line.endsWith(",")) {
        side = "active";
      }

      let command = line
        .substring(0, line.length - 1)
        .trim()
        .toLowerCase();

      //Check phase command
      if (command === "day" || command === "d" || command === "night" || command === "n") {
        if (command === "day" || command === "d") {
          newEvent.phase = "Day";
        } else if (command === "night" || command === "n") {
          newEvent.phase = "Night";
        }

        //Splice the line out of the notes
        const newLines = [...lines];
        newLines.splice(i, 1);
        notes = newLines.join("\n");
        continue;
      }

      if (commandChar === "+") {
        //if the command ends with a +, it's supposed to add a new event

        //first, find the type of the event
        let type = null;
        for (const eventType in EVENTS) {
          const foundTypes = findInList(EVENTS[eventType], command);
          if (foundTypes.length === 1) {
            type = foundTypes[0];
            break;
          }
        }

        if (type === null) {
          //if the type is not found, don't add the event
          continue;
        } else {
          const newLines = [...lines];
          newLines.splice(i, 1);
          notes = newLines.join("\n");

          addEvent(type, {
            ...event,
            notes: notes,
          });
          return;
        }
      }

      //if the command is a player's name or a character's name, add it to the respective list
      // const player = players.find((p) => p.name.toLowerCase() === command);
      const playersFound = findInList(players, command, (player) => player.name);
      if (playersFound.length === 1) {
        const player = playersFound[0];
        const listName = side + "Players";
        const newList = [...newEvent[listName]];
        newList.push(player.name);
        newEvent[listName] = newList;

        //splice the line out of the notes
        const newLines = [...lines];
        newLines.splice(i, 1);
        notes = newLines.join("\n");

        continue;
      }

      for (const characterType in script.characters) {
        const characterList = script.characters[characterType];
        // const character = characterList.find((c) => c.toLowerCase() === command);
        const characters = findInList(characterList, command);
        if (characters.length === 1) {
          const character = characters[0];
          const listName = side + "Characters";
          const newList = [...newEvent[listName]];
          newList.push({
            character: character,
            characterType: characterType,
          });
          newEvent[listName] = newList;

          //splice the line out of the notes
          const newLines = [...lines];
          newLines.splice(i, 1);
          notes = newLines.join("\n");

          break;
        }
      }
    }

    newEvent.notes = notes;
    setEvent(newEvent);
  };

  const eventStyle = getEventStyle(event.type);
  const style = {
    // border: "1px solid " + eventStyle.ambient,
    // borderRadius: "5px",
  };
  const notesLineHeight = 14;
  const notesHeight = 8 + Math.max(event.notes.split("\n").length, 1) * notesLineHeight + "px";

  const elementCounts = getEventDefaultElementCounts(event.type);

  return (
    <div className="event" style={style}>
      <PhaseButton
        phase={event.phase}
        setPhase={(phase) => setEvent({ ...event, phase: phase })}
        number={phaseNumber}
      />
      <EventTypeComboBox type={event.type} setType={(type) => setEvent({ ...event, type: type })} />

      <PlayerCharacterList
        playerList={event.activePlayers}
        setPlayerList={(list) => setPlayerList("activePlayers", list)}
        characterList={event.activeCharacters}
        setCharacterList={(list) => setCharacterList("activeCharacters", list)}
        minPlayers={elementCounts[0]}
        minCharacters={elementCounts[1]}
      />

      <textarea
        value={event.notes}
        onChange={(e) => setNotes(e.target.value)}
        style={{
          resize: "none",
          lineHeight: notesLineHeight + "px",
          height: notesHeight,
          fontSize: ".8rem",
        }}
        autoFocus
        // rows={Math.max(event.notes.split("\n").length - 1, 1)}
      />

      <PlayerCharacterList
        playerList={event.passivePlayers}
        setPlayerList={(list) => setPlayerList("passivePlayers", list)}
        characterList={event.passiveCharacters}
        setCharacterList={(list) => setCharacterList("passiveCharacters", list)}
        minPlayers={elementCounts[2]}
        minCharacters={elementCounts[3]}
      />

      {/* <button onClick={deleteEvent}>X</button> */}
      <DeleteButton onDelete={deleteEvent} />
    </div>
  );
}

function PlayerCharacterList({
  playerList,
  setPlayerList,
  characterList,
  setCharacterList,
  minPlayers = 0,
  minCharacters = 0,
}) {
  const setPlayer = (name, index) => {
    const newList = [...playerList];
    if (name === null) {
      newList.splice(index, 1);
    } else {
      newList[index] = name;
    }
    setPlayerList(newList);
  };
  const addPlayer = (name) => {
    const newList = [...playerList];
    newList.push(name);
    setPlayerList(newList);
  };
  const setCharacter = (character, index) => {
    const newList = [...characterList];
    if (character === null) {
      newList.splice(index, 1);
    } else {
      newList[index] = character;
    }
    setCharacterList(newList);
  };
  const addCharacter = (character) => {
    const newList = [...characterList];
    newList.push(character);
    setCharacterList(newList);
  };

  const onDragEnter = (e) => {
    if (e.target !== e.currentTarget) return;
    const text = e.dataTransfer.getData("text/plain");
    if (text === "") return;
    const data = JSON.parse(text);
    if (data.type !== "player" && data.type !== "character") return;
    e.preventDefault();
    e.target.style.backgroundColor = "rgb(220, 220, 220)";
  };
  const onDragLeave = (e) => {
    const text = e.dataTransfer.getData("text/plain");
    if (text === "") return;
    const data = JSON.parse(text);

    if (data.type !== "player" && data.type !== "character") return;
    e.target.style.backgroundColor = "";
  };
  const onDrop = (e) => {
    if (e.target !== e.currentTarget) return;
    const text = e.dataTransfer.getData("text/plain");
    if (text === "") return;
    const data = JSON.parse(text);

    if (data.type !== "player" && data.type !== "character") return;
    e.target.style.backgroundColor = "";

    if (data.type === "player") {
      addPlayer(data.name);
    } else if (data.type === "character") {
      addCharacter({
        character: data.character,
        characterType: data.characterType,
      });
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const playerListWithMin = [...playerList];
  if (playerList.length < minPlayers) {
    for (let i = playerList.length; i < minPlayers; i++) {
      playerListWithMin.push(null);
    }
  }

  const characterListWithMin = [...characterList];
  if (characterList.length < minCharacters) {
    for (let i = characterList.length; i < minCharacters; i++) {
      characterListWithMin.push(null);
    }
  }

  return (
    <div
      className="player-character-list"
      draggable={true}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="event-vertical-list">
        {playerListWithMin.map((name, index) => (
          <PlayerDisplay
            key={index}
            name={name}
            setName={(name) => setPlayer(name, index)}
            onDelete={() => setPlayer(null, index)}
          />
        ))}
      </div>
      <div className="event-vertical-list">
        {characterListWithMin.map((character, index) => (
          <CharacterDisplay
            key={index}
            character={character}
            setCharacter={(character) => setCharacter(character, index)}
            onDelete={() => setCharacter(null, index)}
          />
        ))}
      </div>
    </div>
  );
}

function PhaseSeparator({ phase, phaseNumber }) {
  return (
    <div className="phase-separator">
      <hr />
      <h2>{phase + " " + phaseNumber}</h2>
      <hr />
    </div>
  );
}

function PhaseSeparatorWinner({ goodWon }) {
  const classes = "phase-separator " + (goodWon ? "good-won" : "evil-won");
  const text = goodWon ? "Good Wins!" : "Evil Wins!";

  return (
    <div className={classes}>
      <hr />
      <h2>{text}</h2>
      <hr />
    </div>
  );
}

//This function should return all elements in the list that start with the search string
//OR only the exact element if its an exact match, even if there are other elements that start with the search string
function findInList(list, search, listKeyFn = null) {
  const exactMatch = list.find((item) =>
    listKeyFn === null
      ? item.toLowerCase() === search.toLowerCase()
      : listKeyFn(item).toLowerCase() === search.toLowerCase()
  );
  if (exactMatch) return [exactMatch];
  const matches = list.filter((item) =>
    listKeyFn === null
      ? item.toLowerCase().startsWith(search.toLowerCase())
      : listKeyFn(item).toLowerCase().startsWith(search.toLowerCase())
  );

  return matches.length > 0 ? [matches[0]] : [];
}

import { useState } from "react";
import "./Character.css";
import { EditButton } from "./Misc";
import officialCharacters from "../data/official-character-list.json";
import { useDropzone } from "react-dropzone";

import scriptTb from "../data/scripts/tb.json";
import scriptSav from "../data/scripts/sav.json";
import scriptBmr from "../data/scripts/bmr.json";

export function ScriptDisplay({ script, setScript, state }) {
  const [editable, setEditable] = useState(false);
  const [scriptString, setScriptString] = useState("");
  const [scriptImport, setScriptImport] = useState("");
  const [previousScriptsIndex, setPreviousScriptsIndex] = useState(-1);

  let previousScripts = getUniquePlayedScripts(state);

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    setAcceptedFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const toggleEditable = () => {
    const newEditable = !editable;
    if (newEditable) {
      setScriptString(scriptToString(script));
      setScriptImport("");
      setPreviousScriptsIndex(-1);
    } else {
      if (scriptImport === "" && acceptedFiles.length === 0 && previousScriptsIndex === -1) {
        setScript(stringToScript(scriptString));
      } else {
        if (acceptedFiles.length > 0) {
          const reader = new FileReader();
          reader.onload = () => {
            setScript(parseOfficialScript(reader.result));
          };
          reader.readAsText(acceptedFiles[0]);
          setAcceptedFiles([]);
        } else if (previousScriptsIndex !== -1) {
          setScript(previousScripts[previousScriptsIndex]);
        } else {
          setScript(parseOfficialScript(scriptImport));
        }
      }
    }
    setEditable(!editable);
  };

  const baseScripts = [
    {
      name: "Trouble Brewing",
      abbreviation: "tb",
      script: scriptTb,
    },
    {
      name: "Sects & Violets",
      abbreviation: "sav",
      script: scriptSav,
    },
    {
      name: "Bad Moon Rising",
      abbreviation: "bmr",
      script: scriptBmr,
    },
  ];
  const selectBaseScript = (name) => {
    setAcceptedFiles([]);
    let json;
    for (const script of baseScripts) {
      if (script.abbreviation === name) {
        json = script.script;
        break;
      }
    }
    setScript(parseOfficialScript(JSON.stringify(json)));

    setEditable(false);
  };

  const scriptToString = (script) => {
    let scriptString = script.name + "\n";
    for (const [key, value] of Object.entries(script.characters)) {
      scriptString += "---\n";
      scriptString += value.join("\n") + "\n";
    }
    return scriptString;
  };
  const stringToScript = (scriptString) => {
    const script = { name: "", characters: {} };
    const characterKeys = ["Townsfolk", "Outsider", "Minion", "Demon"];
    const lines = scriptString.trim().split("\n");

    script.name = lines[0];

    //Loop through lines, look for "---"
    //When you find "---", it marks the end of the previous character type and the start of the next character type
    //by incrementing the currentCharacterTypeIndex and looking at the characterKeys array

    let currentCharacterTypeIndex = 0;
    let currentCharacterType = characterKeys[currentCharacterTypeIndex];
    script.characters[currentCharacterType] = [];
    for (let i = 2; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line === "") continue;

      if (line === "---") {
        currentCharacterTypeIndex++;
        if (currentCharacterTypeIndex >= characterKeys.length) break;
        currentCharacterType = characterKeys[currentCharacterTypeIndex];
        script.characters[currentCharacterType] = [];
      } else {
        script.characters[currentCharacterType].push(line);
      }
    }

    return script;
  };

  if (editable) {
    return (
      <div style={{ display: "flex", flexDirection: "column", fontSize: ".8rem" }}>
        <EditButton onClick={toggleEditable} text="Done" />
        <textarea
          value={scriptString}
          onChange={(event) => setScriptString(event.target.value)}
          rows={scriptString.split("\n").length - 1}
          style={{ lineHeight: "1rem", resize: "none" }}
        />
        <h5 style={{ margin: "5px 0 2px 0" }}>Import Script</h5>
        <p style={{ margin: "0" }}>Paste the script JSON here</p>
        <input
          type="text"
          value={scriptImport}
          onChange={(event) => setScriptImport(event.target.value.trim())}
        />
        <p style={{ margin: "0" }}>or drag the file here</p>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <p>Selected '{acceptedFiles[0].name}'</p>
          ) : (
            <p>Drag 'n' drop script here</p>
          )}
        </div>
        <p style={{ margin: "0" }}>or select the script</p>
        {baseScripts.map((script) => (
          <button
            style={{ marginTop: "3px" }}
            key={script.abbreviation}
            onClick={() => selectBaseScript(script.abbreviation)}
          >
            {script.name}
          </button>
        ))}
        <p style={{ margin: "0" }}>or select a previously played script</p>
        <PreviouslyPlayedScriptsSelect
          index={previousScriptsIndex}
          setIndex={setPreviousScriptsIndex}
          scripts={previousScripts}
        />
      </div>
    );
  }

  //Count characters, and if there are more than 25, set a boolean property to true
  let characterCount = 0;
  for (const [key, value] of Object.entries(script.characters)) {
    characterCount += value.length;
  }
  const tooManyCharacters = characterCount > 29;

  return (
    <div className="character-list">
      <div style={{ display: "flex" }}>
        <h3 style={{ flex: "1" }}>{script.name}</h3>
        <EditButton onClick={toggleEditable} />
      </div>
      {Object.entries(script.characters).map(([key, value]) => (
        <div key={key}>
          {tooManyCharacters ? null : <h4>{key}</h4>}
          {value.map((character, index) => (
            <CharacterSource key={index} character={character} characterType={key} />
          ))}
        </div>
      ))}
    </div>
  );
}

//Make this not editable, add component PlayerList which is editable
export function CharacterSource({ character, characterType }) {
  const onDragStart = (event) => {
    if (character === null) return;
    event.dataTransfer.clearData();
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        type: "character",
        character: character,
        characterType: characterType,
      })
    );
  };

  const classes = ["character", "character-source"];
  if (character !== null) {
    classes.push(characterType.toLowerCase());
  }

  return (
    <div className={classes.join(" ")} draggable={true} onDragStart={onDragStart}>
      {character}
    </div>
  );
}

export function CharacterDisplay({ character, setCharacter, onDelete, editable = true }) {
  const onDragStart = (event) => {
    if (character === null) return;
    event.dataTransfer.clearData();
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        type: "character",
        character: character.character,
        characterType: character.characterType,
      })
    );
  };
  const onDrop = (event) => {
    event.target.style.backgroundColor = "";
    const text = event.dataTransfer.getData("text/plain");
    if (text === "") return;
    const data = JSON.parse(text);
    if (data.type !== "character") return;
    const character = data.character;

    if (!editable) return;

    if (character !== undefined && character !== null && character !== "") {
      setCharacter({
        character: character,
        characterType: data.characterType,
      });
    }
  };
  const onDragEnter = (event) => {
    const text = event.dataTransfer.getData("text/plain");
    if (text === "") return;
    const data = JSON.parse(text);
    if (data.type !== "character") return;

    if (!editable) return;

    event.preventDefault();
    event.target.style.backgroundColor = "rgb(220, 220, 220)";
  };
  const onDragLeave = (event) => {
    event.target.style.backgroundColor = "";
  };

  const classes = ["character", "character-display"];
  if (character !== null && character !== undefined) {
    classes.push(character.characterType.toLowerCase());
  }
  if (!editable) classes.push("not-editable");

  return (
    <div
      className={classes.join(" ")}
      draggable={true}
      onDragStart={onDragStart}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
    >
      {character === null || character === undefined ? "" : character.character}
      {character !== null && character !== undefined && editable && <DeleteButton onDelete={onDelete} />}
    </div>
  );
}

export function DeleteButton({ onDelete }) {
  return (
    <button
      className="delete-button"
      onClick={() => {
        onDelete();
      }}
      value={"X"}
    >
      X
    </button>
  );
}

export function parseOfficialScript(string) {
  console.log("Parsing official script", string);
  const characterList = JSON.parse(string);
  const script = {
    name: "Unnamed Script",
    characters: {
      Townsfolk: [],
      Outsider: [],
      Minion: [],
      Demon: [],
    },
  };

  //Loop through characters
  for (const character of characterList) {
    //If character is a string, its the characters id
    if (typeof character === "string") {
      let characterProps = getCharacterFromId(character);
      if (characterProps === null) {
        console.log("Character not found: " + character);
        continue;
      }
      const team = officialTeamToCharacterType(characterProps.team);
      script.characters[team].push(characterProps.name);
    } else {
      //Character is an object
      const id = character.id;
      //if the character's id is "_meta", it contains the script name
      if (id === "_meta") {
        script.name = character.name ?? "<No Name>";
      } else {
        //If there is no name property, use the id to get the characters properties
        let characterProps;
        if (character.name === undefined) {
          characterProps = getCharacterFromId(id);
        } else {
          characterProps = character;
        }
        if (characterProps === null) {
          console.log("Character not found: " + id);
          continue;
        }
        const team = officialTeamToCharacterType(characterProps.team);
        script.characters[team].push(characterProps.name);
      }
    }
  }

  return script;
}

export function getCharacterFromId(id) {
  let character = officialCharacters.find((character) => character.id === id);
  if (character === undefined) {
    let cleanedId = id.replace("_", "").replace("-", "");
    character = officialCharacters.find((character) => character.id === cleanedId);
  }
  if (character === undefined) return null;
  return character;
}

export function officialTeamToCharacterType(team) {
  if (team === "townsfolk") return "Townsfolk";
  if (team === "outsider") return "Outsider";
  if (team === "minion") return "Minion";
  if (team === "demon") return "Demon";
}

export function PreviouslyPlayedScriptsSelect({ index, setIndex, scripts }) {
  return (
    <select
      value={index}
      onChange={(event) => {
        setIndex(event.target.value);
      }}
    >
      <option value={-1}>--- Select a script ---</option>
      {scripts.map((script, index) => (
        <option key={index} value={index}>
          {script.name}
        </option>
      ))}
    </select>
  );
}

export function getUniquePlayedScripts(state) {
  const scripts = []; //Array of unique scripts, with all the scripts that have been played before
  for (var i = 0; i < state.games.length; i++) {
    const game = state.games[i];
    if (i === state.selectedGame) continue;

    if (game.script.name !== undefined) {
      let scriptExists = false;
      for (const script of scripts) {
        if (script.name === game.script.name) {
          scriptExists = true;
          break;
        }
      }
      if (!scriptExists) {
        scripts.push(game.script);
      }
    }
  }
  //Sort scripts by name
  scripts.sort((a, b) => a.name.localeCompare(b.name));
  return scripts;
}

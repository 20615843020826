import "./PlayerNotes.css";
import { PlayerSource, playerIsAlive } from "./Player";
import { CharacterDisplay } from "./Character";
import { faAutomobile, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlignmentButton } from "./BotcComponents";
import { Button, ButtonGroup } from "react-bootstrap";

export function PlayerNotesContainer({ players, setPlayers, events }) {
  const setPlayer = (player, index) => {
    const newPlayers = [...players];
    newPlayers[index] = player;
    setPlayers(newPlayers);
  };

  const autoDetermineCharacters = () => {
    const newPlayers = [...players];
    for (let i = 0; i < newPlayers.length; i++) {
      const player = newPlayers[i];
      //If the player already has at least one of their characters set, skip them
      if (player.characters.some((c) => c !== null)) continue;

      //Loop backwards through events and search for an event.type === "Exchanged Roles"
      //If found, set the player's characters to the event.activeCharacters in the event
      let characters = [];
      for (let j = events.length - 1; j >= 0; j--) {
        const event = events[j];
        if (
          (event.type === "Exchanged Roles" || event.type === "Claim") &&
          event.activePlayers.includes(player.name)
        ) {
          characters = [...event.activeCharacters];
          break;
        }
      }

      //Maximum of 3 characters
      if (characters.length > 3) {
        characters = characters.slice(0, 3);
      }
      newPlayers[i] = { ...player, characters: characters };
    }
    setPlayers(newPlayers);
  };
  const clearCharacters = () => {
    const newPlayers = [...players];
    for (let i = 0; i < newPlayers.length; i++) {
      newPlayers[i] = { ...newPlayers[i], characters: [] };
    }
    setPlayers(newPlayers);
  };

  return (
    <div id="player-notes-container">
      <div className="row-items">
        <h2 style={{ flexGrow: "1" }}>Player Notes</h2>
        <ButtonGroup>
          <Button onClick={autoDetermineCharacters} variant="outline-light">
            <FontAwesomeIcon icon={faAutomobile} /> Auto-Fill Empty Chars
          </Button>
          <Button onClick={clearCharacters} variant="outline-light">
            <FontAwesomeIcon icon={faTrash} /> Clear All Chars
          </Button>
        </ButtonGroup>
      </div>
      {players.map((player, index) => {
        return (
          <div key={index}>
            <hr />
            <PlayerNotes
              player={player}
              setPlayer={(player) => {
                setPlayer(player, index);
              }}
              events={events}
            />
          </div>
        );
      })}
    </div>
  );
}

function PlayerNotes({ player, setPlayer, events }) {
  const characterListWithMin = [...player.characters];
  if (player.characters.length < 3) {
    for (let i = player.characters.length; i < 3; i++) {
      characterListWithMin.push(null);
    }
  }

  const setCharacter = (character, index) => {
    const newCharacters = [...player.characters];
    newCharacters[index] = character;
    setPlayer({ ...player, characters: newCharacters });
  };
  const setAlignment = (alignment) => {
    setPlayer({ ...player, alignment: alignment });
  };

  return (
    <div className="player-notes">
      <div className="column-items-full-width">
        <PlayerSource
          name={player.name}
          isAlive={playerIsAlive(player.name, events)}
          style={{ fontSize: "1.5rem", height: "34px" }}
        />
        <AlignmentButton
          alignment={player.alignment}
          setAlignment={setAlignment}
          style={{ marginTop: "2px" }}
        />
      </div>

      <textarea
        value={player.notes}
        onChange={(event) => setPlayer({ ...player, notes: event.target.value })}
        rows={player.notes.split("\n").length - 1}
      />
      <div>
        {characterListWithMin.map((character, index) => (
          <CharacterDisplay
            key={index}
            character={character}
            setCharacter={(character) => setCharacter(character, index)}
            onDelete={() => setCharacter(null, index)}
          />
        ))}
      </div>
    </div>
  );
}

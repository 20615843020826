import { useState } from "react";
import "./Player.css";
import { DeleteButton } from "./Character";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross, faHeart, faSkull, faUser } from "@fortawesome/free-solid-svg-icons";
import { EditButton } from "./Misc";
import { EVENTS_MAPPING } from "../data/models/Events";
import { getDefaultPlayerInfo } from "../data/models/Player";

export function PlayerList({ players, setPlayers, events }) {
  const [editable, setEditable] = useState(false);
  const [playerListString, setPlayerListString] = useState(players.map((p) => p.name).join("\n"));

  const toggleEditable = () => {
    const newEditable = !editable;
    if (newEditable) {
      setPlayerListString(players.map((p) => p.name).join("\n"));
    } else {
      const playerNames = playerListString.trim().split("\n");
      const newPlayers = [...players];
      console.log("Old players: ", players, "playerNames: ", playerNames);
      let index;
      for (index = 0; index < playerNames.length; index++) {
        //Replace player's names with new names
        if (index >= newPlayers.length) {
          newPlayers.push({ ...getDefaultPlayerInfo(), name: playerNames[index] });
        } else {
          newPlayers[index].name = playerNames[index];
        }
      }
      //Remove extra players
      newPlayers.splice(index, newPlayers.length - index);
      console.log("New players: ", newPlayers);
      setPlayers(newPlayers);
    }

    setEditable(newEditable);
  };

  if (editable) {
    return (
      <div className="player-list">
        <div style={{ display: "flex", flexDirection: "column", fontSize: ".8rem" }}>
          <EditButton onClick={toggleEditable} text="Done" />
          <textarea
            value={playerListString}
            onChange={(event) => setPlayerListString(event.target.value)}
            rows={playerListString.split("\n").length - 1}
            style={{ lineHeight: "1rem" }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="player-list">
      <div style={{ display: "flex" }}>
        <h3 style={{ flex: "1" }}>Players</h3>
        <EditButton onClick={toggleEditable} />
      </div>
      {players.map((player, index) => (
        <PlayerSource key={index} name={player.name} isAlive={playerIsAlive(player.name, events)} />
      ))}
    </div>
  );
}

//Make this not editable, add component PlayerList which is editable
export function PlayerSource({ name, isAlive, style }) {
  const onDragStart = (event) => {
    if (name === null) return;
    event.dataTransfer.clearData();
    event.dataTransfer.setData("text/plain", JSON.stringify({ type: "player", name: name }));
  };

  return (
    <div className="player player-source" draggable={true} onDragStart={onDragStart} style={style}>
      <FontAwesomeIcon icon={isAlive ? faHeart : faSkull} color={isAlive ? "#009900" : "#cc0000"} size="sm" />
      {name}
    </div>
  );
}

export function PlayerDisplay({ name, setName, onDelete }) {
  const onDragStart = (event) => {
    if (name === null) return;
    event.dataTransfer.clearData();
    event.dataTransfer.setData("text/plain", JSON.stringify({ type: "player", name: name }));
  };
  const onDrop = (event) => {
    event.target.style.backgroundColor = "";
    const text = event.dataTransfer.getData("text/plain");
    if (text === "") return;
    const data = JSON.parse(text);
    if (data.type !== "player") return;
    const name = data.name;

    if (name !== undefined && name !== null && name !== "") {
      setName(name);
    }
  };
  const onDragEnter = (event) => {
    const text = event.dataTransfer.getData("text/plain");
    if (text === "") return;
    const data = JSON.parse(text);
    if (data.type !== "player") return;

    event.preventDefault();
    event.target.style.backgroundColor = "rgb(190, 190, 190)";
  };
  const onDragLeave = (event) => {
    event.target.style.backgroundColor = "";
  };

  const classes = ["player", "player-display"];
  if (name === null || name === undefined) classes.push("player-display-empty");

  return (
    <div
      className={classes.join(" ")}
      draggable={true}
      onDragStart={onDragStart}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
    >
      <FontAwesomeIcon icon={faUser} />
      {name ?? ""}
      {name !== null && name !== undefined && <DeleteButton onDelete={onDelete} />}
    </div>
  );
}

export function playerIsAlive(playerName, events) {
  const deathEvents = EVENTS_MAPPING["Death"];
  const resurrectEvents = EVENTS_MAPPING["Resurrection"];
  //Iterate backwards through the event list
  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i];
    if (deathEvents.indexOf(event.type) !== -1 && event.activePlayers.indexOf(playerName) !== -1) {
      return false;
    }
    if (resurrectEvents.indexOf(event.type) !== -1 && event.activePlayers.indexOf(playerName) !== -1) {
      return true;
    }
  }
  return true; //If no events found, player is alive
}

import "./LeftSidebar.css";
import { PlayerList } from "./Player";
import { DoubleConfirmButton, TransparentHorizontalRule } from "./Misc";
import { CharacterDisplay } from "./Character";
import { getDefaultGame } from "../data/models/Game";
import { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faEdit,
  faExternalLink,
  faQuestion,
  faThumbsDown,
  faThumbsUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AlignmentButton, getAlignmentFromCharacter } from "./BotcComponents";
import { PaginationControl } from "react-bootstrap-pagination-control";

export default function LeftSidebar({ playerList, setPlayerList, bluffs, setBluffs, state, setState }) {
  const setBluff = (bluff, index) => {
    const newBluffs = [...bluffs];
    newBluffs[index] = bluff;
    setBluffs(newBluffs);
  };

  return (
    <div id="left-sidebar-container" style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PlayerList
          players={playerList}
          setPlayers={setPlayerList}
          events={state.games[state.selectedGame].events}
        />
        <TransparentHorizontalRule />
        <h3>Bluffs</h3>
        {bluffs.map((bluff, index) => (
          <CharacterDisplay
            key={index}
            character={bluff}
            setCharacter={(character) => setBluff(character, index)}
            onDelete={() => setBluff(null, index)}
          />
        ))}

        <TransparentHorizontalRule style={{ flexGrow: "1", margin: "0" }} />

        <RandomNumberGenerator />

        <GameNavigation state={state} setState={setState} />
      </div>
    </div>
  );
}

function RandomNumberGenerator() {
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(10);
  const [result, setResult] = useState(null);
  const [animationCounter, setAnimationCounter] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const generateNumber = () => {
    //Make it a funny animation
    //Set the counter to 100, and count down 1 each x ms, while setting it to a random number
    //When the counter reaches 0, set the result to the random number and stop the interval
    setAnimationCounter(30);

    const interval = setInterval(() => {
      setAnimationCounter((counter) => counter - 1);
    }, 30);
    setIntervalId(interval);
  };

  useEffect(() => {
    if (animationCounter === 0) {
      clearInterval(intervalId);
      setResult(Math.floor(Math.random() * (max - min + 1) + min));
    } else {
      setResult(Math.floor(Math.random() * (max - min + 1) + min));
    }
  }, [animationCounter]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px" }}>
        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        </div> */}
        <Form.Group>
          <Form.Label>Min</Form.Label>
          <Form.Control type="number" value={min} onChange={(e) => setMin(e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Max</Form.Label>
          <Form.Control type="number" value={max} onChange={(e) => setMax(e.target.value)} />
        </Form.Group>
      </div>
      <Button
        onClick={generateNumber}
        style={{ width: "100%", marginTop: "4px" }}
        disabled={animationCounter > 0}
      >
        Generate
      </Button>
      <p>Random Number: {result}</p>
    </div>
  );
}

function GameNavigation({ state, setState }) {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const [importString, setImportString] = useState("");
  const [alignment, setAlignment] = useState(null);

  //state contains ALL the data for the current and previous games
  //state = { games: [], selectedGame: 0 }

  const newGame = () => {
    setState({ ...state, games: [getDefaultGame(), ...state.games], selectedGame: 0 });
    closeModal();
  };
  const onGameSelect = (index) => {
    setState({ ...state, selectedGame: index });
    closeModal();
  };
  const onGameDelete = (index) => {
    if (state.games.length === 1) return;

    setState({
      ...state,
      games: state.games.filter((_, i) => i !== index),
      selectedGame: 0,
    });
  };
  const importGame = () => {
    try {
      const game = JSON.parse(importString);
      setState({ ...state, games: [game, ...state.games], selectedGame: state.selectedGame + 1 });
    } catch (error) {
      alert("Invalid JSON");
    }
  };

  const [page, setPage] = useState(1);
  const totalGames = state.games.length;
  const perPage = 8;
  const gamesToDisplay = state.games.slice((page - 1) * perPage, page * perPage);

  return (
    <div style={{ fontSize: ".8rem" }}>
      <p style={{ marginBottom: "5px", marginTop: "3px" }}>
        Game: #{state.games.length - state.selectedGame} of {state.games.length}
        <br />
        Played on: {state.games[state.selectedGame].datePlayed.toLocaleDateString()}
      </p>
      <div id="game-navigation">
        <Button onClick={openModal} size="sm" style={{ gridColumn: "1 / 3" }}>
          Game History
        </Button>
        <Modal show={modalOpen} onHide={closeModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Game History</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <PaginationControl
              page={page}
              between={3}
              total={totalGames}
              limit={perPage}
              changePage={(page) => {
                setPage(page);
              }}
              ellipsis={1}
            />
            <div id="history-container">
              {gamesToDisplay.map((game) => {
                const index = state.games.indexOf(game);
                return (
                  <GameHistoryGame
                    key={index}
                    index={index}
                    game={game}
                    gameCount={state.games.length}
                    selectGame={onGameSelect}
                    deleteGame={onGameDelete}
                    selectedGameIndex={state.selectedGame}
                  />
                );
              })}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="row-items w-100">
              <div className="spaced-items">
                <Form.Control
                  placeholder="Paste game JSON here"
                  value={importString}
                  onChange={(e) => setImportString(e.target.value)}
                />
                <Button variant="secondary" onClick={importGame} style={{ whiteSpace: "nowrap" }}>
                  Import Game
                </Button>
              </div>
              <Button variant="primary" onClick={newGame}>
                New Game
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

function GameHistoryGame({ index, game, gameCount, selectGame, deleteGame, selectedGameIndex }) {
  const [hasExported, setHasExported] = useState(false);

  const exportGame = (e) => {
    //export game to clipboard as JSON
    navigator.clipboard.writeText(JSON.stringify(game));
    e.stopPropagation();
    setHasExported(true);
    setTimeout(() => setHasExported(false), 3000);
  };

  let datePlayed = game.datePlayed ?? new Date(0);
  if (typeof datePlayed === "string") datePlayed = new Date(datePlayed);

  const receivedRoleEvent = game.events.find((event) => event.type === "Received Role");
  const startingCharacter = receivedRoleEvent?.activeCharacters[0] ?? null;
  const ownPlayerName = receivedRoleEvent?.activePlayers[0] ?? null;
  let ownPlayerIndex = game.players.findIndex((player) => player?.name === ownPlayerName);
  if (ownPlayerIndex === -1) ownPlayerIndex = 0;

  let endingCharacter = null;
  if (
    game.players[ownPlayerIndex] &&
    game.players[ownPlayerIndex].characters &&
    game.players[ownPlayerIndex].characters
  ) {
    endingCharacter = game.players[ownPlayerIndex].characters[0];
  }

  if (index === selectedGameIndex) {
    console.log(
      "current game: ",
      game,
      ", ownPlayerIndex: ",
      ownPlayerIndex,
      ", startingCharacter: ",
      startingCharacter,
      ", endingCharacter: ",
      endingCharacter,
      ", ownPlayerName: ",
      ownPlayerName
    );
  }

  let endingAlignment = game.players[ownPlayerIndex] ? game.players[ownPlayerIndex].alignment : null ?? null;
  if (endingAlignment === null) {
    endingAlignment = getAlignmentFromCharacter(endingCharacter ?? startingCharacter);
  }

  const winnerEvent = game.events.find(
    (event) => event.type === "Winner: Good" || event.type === "Winner: Evil"
  );
  const winner =
    winnerEvent?.type === "Winner: Good" ? "Good" : winnerEvent?.type === "Winner: Evil" ? "Evil" : "";
  const winnerIcon = winner === "" ? faQuestion : winner === "Good" ? faThumbsUp : faThumbsDown;

  let finalDayNumber = 0;
  let lastPhase = "Day";
  for (let i = 0; i < game.events.length; i++) {
    const event = game.events[i];
    if (event.phase === "Day" && lastPhase === "Night") {
      finalDayNumber++;
    }
    lastPhase = event.phase;
  }

  const classes = ["history-game"];
  if (index === selectedGameIndex) classes.push("selected");

  return (
    <div className={classes.join(" ")} onClick={() => selectGame(index)}>
      <h4 className="text-center">Game {gameCount - index}</h4>
      <p>Played on:</p>
      <p className="text-center">{datePlayed.toLocaleDateString()}</p>
      <p>Script:</p>
      <p>{game.script.name}</p>
      <p>Players:</p>
      <p>{game.players.length}</p>
      <p>Your Role:</p>
      <div className="column-items-full-width">
        <CharacterDisplay character={startingCharacter} editable={false} />
        {startingCharacter && endingCharacter && startingCharacter.character !== endingCharacter.character ? (
          <CharacterDisplay character={endingCharacter} editable={false} />
        ) : null}
      </div>
      <p>Your Alignment:</p>
      <AlignmentButton alignment={endingAlignment} editable={false} stopPropagation />
      <p>Winner:</p>
      <Badge bg={winner === "Good" ? "primary" : winner === "Evil" ? "danger" : "secondary"}>
        {winner} <FontAwesomeIcon icon={winnerIcon} />
      </Badge>
      <p>Final Day:</p>
      <p>{finalDayNumber}</p>
      <div className="row-items-full-width" style={{ gridColumn: "1 / 3" }}>
        <Button onClick={exportGame} variant={hasExported ? "success" : "outline-primary"} size="sm">
          {hasExported ? "Exported" : "Export"}{" "}
          <FontAwesomeIcon icon={hasExported ? faThumbsUp : faClipboard} />
        </Button>
        <DoubleConfirmButton
          onClick={(e) => e.stopPropagation()}
          onConfirm={(e) => {
            e.stopPropagation();
            deleteGame(index);
          }}
          text="Delete"
          confirmText="Really?"
        >
          {" "}
          <FontAwesomeIcon icon={faTrash} />
        </DoubleConfirmButton>
      </div>
    </div>
  );
}

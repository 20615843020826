export function getDefaultGame() {
  return {
    players: [],
    bluffs: [null, null, null],
    script: {
      name: "Trouble Brewing",
      characters: {
        Townsfolk: [
          "Chef",
          "Investigator",
          "Washerwoman",
          "Librarian",
          "Empath",
          "Fortune Teller",
          "Undertaker",
          "Monk",
          "Slayer",
          "Soldier",
          "Ravenkeeper",
          "Virgin",
          "Mayor",
        ],
        Outsider: ["Butler", "Saint", "Recluse", "Drunk"],
        Minion: ["Poisoner", "Spy", "Baron", "Scarlet Woman"],
        Demon: ["Imp"],
      },
    },
    events: [
      {
        phase: "Night",
        type: "Received Role",
        activePlayers: [],
        activeCharacters: [],
        notes: "",
        passivePlayers: [],
        passiveCharacters: [],
      },
    ],
    datePlayed: new Date(),
  };
}
